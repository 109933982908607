<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import '/src/css/ell.css'
export default {}
</script>

<style>
*{
  margin: 0;
}

/* 全局移除 router-link 的下划线 */
a {
  text-decoration: none !important;
}
@media screen and (max-width: 1241px) {
  body {
    width: 2046px;
    height: 100%;
    transform-origin: top left;
    transform: scale(var(--min-zoom));
  }

  :root {
    --min-zoom: 1; /* 默认缩放比例 */
  }
}
</style>
