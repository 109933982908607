import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    //404
    path: '*',
    name: '404',
    component: () => import('../views/New/ERROR/404View.vue'),
    meta: { title: '404' },
  },
  {
    //登录
    path: '/flighyun',
    name: 'login',
    component: () => import('../views/New/LoginView.vue'),
  },
  {
    //首页索引
    path: '/index/:AdminId',
    name: 'index',
    component: () => import('../views/New/indexView.vue'),
  },
  {
    //管理员个人主页
    path: '/Admin/info/',
    name: 'AdminHome',
    component: () => import('../views/New/else/AdminHomePageView.vue'),
  },
  {
    //Flight-飞行员账号
    path: '/Admin/User',
    name: 'User',
    component: () => import('../views/New/Flight/UserView.vue'),
  },
  {
    //Flight-活动
    path: '/Admin/push/Events',
    name: 'Events',
    component: () => import('../views/New/Flight/EventsView.vue'),
  },
  {
    //Flight-VA
    path: '/Admin/VA',
    name: 'VA',
    component: () => import('../views/New/Flight/VAView.vue'),
  },
  {
    //ATC-账户
    path: '/Admin/ATC/All',
    name: 'ATC',
    component: () => import('../views/New/ATC/ATCView.vue'),
  },
  {
    //ATC-招募通知
    path: '/Admin/recruit',
    name: 'recruit',
    component: () => import('../views/New/ATC/recruitView.vue'),
  },
  {
    //ROOT-金钱
    path: '/root/revise/money',
    name: 'money',
    component: () => import('../views/New/ATC/recruitView.vue'),
  },

]
const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || '翔云面板';
  next();
});
export default router

