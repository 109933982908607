export default {
    created() {
        this.checkExpirationTime();
    },
    beforeDestroy() {
        if (this.timerId) {
            clearTimeout(this.timerId);
        }
    },
    methods: {
        checkExpirationTime() {
            const exDataTimeStr = localStorage.getItem('exDataTime');
            if (!exDataTimeStr) {
                console.log('未找到有效的时间戳');
                return;
            }
            const exDataTime = new Date(exDataTimeStr);
            const currentTime = new Date();

            if (currentTime >= exDataTime) {
                localStorage.clear()
                console.log('登录过期，数据已删除');
                this.$message.warning('登录验证过期')
                this.$router.push('/xiangyunadminlogin')
            } else {
                this.timerId = setTimeout(() => this.checkExpirationTime(), 1000);
            }
        },
    }
};