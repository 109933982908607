import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'; // 引入Element UI样式
import globalMixin from './views/sub/globalMixin';

Vue.use(ElementUI);

Vue.config.productionTip = false

Vue.mixin(globalMixin);

new Vue({
  el: '#app',
  data: {
    message: '登录有效中'
  },
  router,
  render: h => h(App)
}).$mount('#app')

